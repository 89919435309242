import { MicroAppConfig, MicroAppRegistryConfig } from "../types";

declare const System: {
    constructor: {
      prototype: {
        createScript: (url: string) => HTMLScriptElement;
      };
    };
    prepareImport: (doProcessScripts: boolean) => Promise<unknown>;
};

export const setupSystemJsContentPolicy = () => {
    const { createScript } = System.constructor.prototype;
    System.constructor.prototype.createScript = function createCrossOriginScript(url: string) {
      const scriptElement: ReturnType<typeof createScript> = createScript.call(this, url);
      scriptElement.removeAttribute('crossorigin');
      return scriptElement;
    };
};

export const createMicroAppAssetsPath = (app: MicroAppRegistryConfig): string => {
  if (app.microAppAssetsUrl) {
    return app.microAppAssetsUrl || '';
  }

  const { appName } = app;
  // Bucket structure
  return `apps/${appName}`;
};

export const injectMicroAppsImportMap = async ({
  microApps,
}: {
  microApps: MicroAppConfig[];
}) => {
  const importMap: Record<string, string> = {};

  microApps.forEach((microApp) => {
    const { appName } = microApp;
    const currentOverride = localStorage.getItem(`import-map-override:${appName}`);
    const remoteEntryPath = `${createMicroAppAssetsPath(microApp)}`;

    importMap[appName] = currentOverride || remoteEntryPath;
  });

  const scriptNode = document.createElement('script');

  scriptNode.setAttribute('type', 'systemjs-importmap');
  scriptNode.setAttribute('id', 'import-apps');
  scriptNode.innerHTML = JSON.stringify({
    imports: importMap,
  });

  document.head.appendChild(scriptNode);

  await System.prepareImport(true);
};