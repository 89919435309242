// import { MicroAppApplicationContext } from '../types/app';
import { MicroAppConfig, MicroAppUIPlacementRegion } from '../types';
import { getPublicApps } from './micro_apps';

const createRouteString = ({ path, appName, module }: { path: string; appName: string; module?: string }): string =>
  `<route path="${path}"><application name="${appName}/${module || 'micro-app-index'}"></application></route>`;

const getRegionalMicroAppRoutes = ({ microApps, region }: { microApps: MicroAppConfig[]; region: MicroAppUIPlacementRegion }) => {
  const appRoutes: string[] = [];

  microApps
    .filter(({ microAppUIPlacementRegion }) => microAppUIPlacementRegion === region)
    .forEach(({ basePaths, appName, module }) => {
      basePaths.forEach(({ path }) => {
        appRoutes.push(createRouteString({ path, appName, module }));
      });
    });

  return appRoutes.join('');
};

const createSingleSpaLayoutString = (microApps: MicroAppConfig[]): string => {
    const mainRegionApps = getRegionalMicroAppRoutes({ microApps, region: MicroAppUIPlacementRegion.MAIN });
    const headerRegionApps = getRegionalMicroAppRoutes({ microApps, region: MicroAppUIPlacementRegion.HEADER });
  return `
    <single-spa-router>
      <header>
        ${headerRegionApps}
      </header>
      <main>
          <route default></route>
          ${mainRegionApps}
      </main>
      <footer>
          <div>To be Replaced with an app</div>
      </footer>
    </single-spa-router>`;
};

export const getMicroAppsLayout = ({
  applicationContext,
  isLoggedIn,
}: {
  applicationContext: any;
  isLoggedIn: boolean;
}): string => {
  const { microApps: apps } = applicationContext.appConfigResult;
  // filter out restricted apps if logged out
  const microApps: MicroAppConfig[] = isLoggedIn ? apps : getPublicApps(apps);

  return createSingleSpaLayoutString(microApps);
};