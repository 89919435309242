import { registerApplication, start, navigateToUrl } from "single-spa";
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { checkAuth } from "./utils/auth";
import { AppState } from "./types";
import { publishAppStateChange } from "./utils/app_state";
import { AppStateContainer } from "./components/AppStateContainer";
import { initApp } from "./app";
// import { registerSingleSpaEvents } from "./utils/single_spa_events";
import { injectMicroAppsImportMap, setupSystemJsContentPolicy } from "./core/system";
import { getMicroAppsLayout } from "./utils/micro_apps_layout";
import { loadApp, registerApplicationWithCustomProps } from "./utils/core";
import { getPublicApps } from "./utils/micro_apps";

// registerApplication(
//   'header',
//   // @ts-ignore
//   () => import('NavApp/Header'),
//   (location) => location.pathname.startsWith('/'),
// );

// registerApplication(
//   'body',
//   // @ts-ignore
//   () => import('BodyApp/Body'),
//   (location) => location.pathname.startsWith('/'),
// );

// const routes = constructRoutes(microfrontendLayout);
// const applications = constructApplications({
//   routes,
//   loadApp({ name }) {
//     console.log('name', name);
//     return System.import(name);
//   },
// });
// const layoutEngine = constructLayoutEngine({ routes, applications });

// applications.forEach(registerApplication);
// layoutEngine.activate();
start();

const initSingleSpa = (applicationContext: any, isLoggedIn: boolean) => {
  const shareScope: string[] = [];
  const microAppLayout = getMicroAppsLayout({ applicationContext, isLoggedIn });
  const routes = constructRoutes(microAppLayout);
  const applications = constructApplications({
    routes,
    loadApp: (appProps) => loadApp(appProps, shareScope),
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach((application) => registerApplicationWithCustomProps(application, applicationContext));
  layoutEngine.activate();
  publishAppStateChange(AppState.AppInitiated);
  start();

  const { search, pathname, origin } = window.location;
  const redirectToHomeUrls = ['/', '/index.html', '/landing']; // list of urls which needs to be redirected to home page
  const currentUserContext = applicationContext.userContextHandler?.getCurrentContext();

  if (!isLoggedIn) {
    // navigate to login page
    navigateToUrl('/');
    return;
  }
  // if there is no user account, redirect to home page.
  // this is the onboarding case
};

const setupApplication = async ({
  appConfigResult,
  isLoggedIn,
  isAppInitiated,
}: any) => {
  const applicationContext = { appConfigResult };
  const { microApps: apps } = appConfigResult;
  const microApps = isLoggedIn ? apps : getPublicApps(apps); // filter out public app

  setupSystemJsContentPolicy();
  await injectMicroAppsImportMap({ microApps });
  initSingleSpa({ ...applicationContext }, isLoggedIn);
};

/**
 * Method which will render app shell react app in container
 * All the global app states like checkAuth, fetchAppConfig, appLoading, appError, notFoundError etc will be render as part of this mini react app
 */
const initAppStateContainerComponent = () => {
  const appStateContainerRoot = createRoot(document.getElementById('pv-usm-ui-container')!);
  appStateContainerRoot.render(React.createElement(React.StrictMode, {}, React.createElement(AppStateContainer)));
};

initAppStateContainerComponent();

checkAuth()
  .then(async (isLoggedIn) => {
    await initApp()
      .then(({ isAppInitiated, appConfigResult }) => {
        // registerSingleSpaEvents();
        setupApplication({ isLoggedIn, isAppInitiated, appConfigResult });
      });
  })
  .catch((error) => console.error(error));
