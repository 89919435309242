import { AppState } from "../types";
import { publishAppStateChange } from "./app_state";
import { UserState } from "@amzn/pvusm-ui-shared-state";

export const checkAuth = async () => {
    publishAppStateChange(AppState.CheckingAuth);
    const userStateInstance = UserState.getInstance();
    // To be replaced by actual Auth Call
    userStateInstance.updateUserPref({
        userContext: {
            orgDetails: {
                orgId: '13123',
                orgIdentifierType: '1P',
                orgName: 'Digiflex'
            },
            userDetails: {
                name: 'Sahil',
                id: 'sahilprj',
                isLoggedIn: true,
            },
            pref: {
                themeMode: 'light',
            }
        }
    });
    return true;
};