import { AppProps, Application, registerApplication } from 'single-spa';
import { SystemJsImportTarget } from '../types/system';
import { constructApplications } from 'single-spa-layout';
import { MicroAppApplicationContext, MicroAppConfig, MicroAppInitializationProps } from '../types';

export const getBasePath = (basePaths?: MicroAppConfig['basePaths']) => {
  if (!basePaths?.length) {
    return '';
  }

  const basePath = basePaths.find(({ path, exact = false }) => {
    if (!exact) {
      return window.location.pathname.startsWith(path);
    }

    return path === window.location.pathname;
  });

  return basePath?.path || '';
};

export const getCustomAppProps = (
  importName: string,
  { appConfigResult }: MicroAppApplicationContext
): MicroAppInitializationProps => {
  const hasMatchingName = (app: MicroAppConfig) => {
    const { appName, module }: { appName: string; module?: string } = app;
    return importName === `${appName}/${module || 'micro-app-index'}`;
  };

  const enabledMicroApps = appConfigResult?.microApps ?? [];
  const microApp = enabledMicroApps.find(hasMatchingName);
  const { basePaths, appName = '' } = microApp ?? ({} as MicroAppConfig);

  const basePath = getBasePath(basePaths);
  const userContext = {};

  return {
    appId: importName,
    basePath,
    microAppName: appName,
  };
};

export const registerApplicationWithCustomProps = (
  application: ReturnType<typeof constructApplications>[number],
  applicationContext: MicroAppApplicationContext
): void => {
  console.log('application', application);
  registerApplication({
    ...application,
    customProps: (name): MicroAppInitializationProps => {
      console.log('custom name', name);
      const customProps = getCustomAppProps(name, applicationContext);
      return customProps;
    },
  });
};

export const loadApp = async ({ name }: AppProps, shareScope: string[]): Promise<Application> => {
  const [packageName, moduleName] = name.split('/');
  const app = await System.import<SystemJsImportTarget>(packageName);
  try {
    app.init(shareScope);
  } catch (e) {
    // already was initialized
  }
  console.log('moduleName', moduleName);
  const module = await app.get(`./${moduleName}`);

  return module();
};