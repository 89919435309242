export enum AppState {
    CheckingAuth = 'CHECKING_AUTH',
    LoadingAppConfig = 'LOADING_APP_CONFIG',
    LoadingPortal = 'LOADING_PORTAL',
    AppInitiated = 'APP_INITIATED',
    AppLoadingError = 'APP_LOADING_ERROR',
    ApiFailureError = 'API_FAILURE_ERROR',
    PageNotFound = 'PAGE_NOT_FOUND',
};

export interface MicroAppInitializationProps {
    appId: string;
    basePath: string;
    microAppName: string;
};

export type MicroAppWebpackConfigByApp = Record<string, string>;

export interface MicroAppApplicationContext extends ApplicationContext {
    microAppWebpackConfigByApp: MicroAppWebpackConfigByApp;
};

export type AppStateEvent = CustomEvent<{
    appState: AppState;
}>;

export interface MicroAppRegistryConfig {
    orgName: string;
    packageName: string;
    appName: string;
    accessLevel: string;
    microAppAssetsUrl?: string;
    module?: string;
};

export enum MicroAppUIPlacementRegion {
    MAIN = 'MAIN',
    HEADER = 'HEADER',
    FOOTER = 'FOOTER',
};

interface BasePath {
    path: string;
    exact?: boolean;
};

export interface MicroAppConfig extends MicroAppRegistryConfig {
    basePaths: BasePath[];
    microAppUIPlacementRegion: MicroAppUIPlacementRegion;
};

export interface AppConfigResult {
    frontend: {
      appRegistryDomain: string;
      appRegistryApiDomain?: string;
      
    };
    microApps: MicroAppConfig[];
}

export interface ApplicationContext {
    appConfigResult: AppConfigResult;
};